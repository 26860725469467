* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f0f2f5;
  color: #333;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.App-header {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #1a73e8;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

input[type="text"] {
  width: 60%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus {
  outline: none;
  border-color: #1a73e8;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #155db1;
}

.loading, .error {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.error {
  color: #d93025;
}

.search-results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.movie-item {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.movie-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.movie-item img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.movie-item h3 {
  padding: 10px;
  text-align: center;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movie-detail {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.movie-detail h2 {
  margin-bottom: 20px;
  color: #1a73e8;
}

.player {
  margin-bottom: 20px;
}

.episodes {
  margin-top: 20px;
}

.episodes h3 {
  margin-bottom: 10px;
  color: #1a73e8;
}

.episodes button {
  margin: 5px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #f1f3f4;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.episodes button:hover {
  background-color: #e8eaed;
}

.episodes button.active {
  background-color: #1a73e8;
  color: white;
}

.episodes button.active:hover {
  background-color: #1967d2;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .search-container {
    flex-direction: column;
    align-items: center;
  }

  input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
  }

  button {
    width: 100%;
    border-radius: 4px;
  }

  .search-results {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
  }

  .movie-item img {
    height: 225px;
  }
}